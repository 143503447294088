<template>
  <div>
    <div class="title">
      <h1>Estadísticas</h1>
      <div class="toolbar">
        <button @click="exportToExcel" type="button" class="btn">Exportar</button>
      </div>
    </div>
    <div class="stats">
      <!--Incomes-->
      <div class="stats__item stats__item--success">
        <span class="stats__title">Ingresos</span>
        <span class="stats__highlight">{{
            Intl.NumberFormat('en-PE', {
              style: 'currency',
              currency: 'USD'
            }).format(this.incomes)
          }}</span>
      </div>
      <!--Expenses-->
      <div class="stats__item stats__item--warning">
        <span class="stats__title">Gastos</span>
        <span class="stats__highlight">{{
            Intl.NumberFormat('en-PE', {
              style: 'currency',
              currency: 'USD'
            }).format(this.expenses)
          }}</span>
      </div>
      <!--Profit-->
      <div class="stats__item">
        <span class="stats__title">Profit</span>
        <span class="stats__highlight">{{
            Intl.NumberFormat('en-PE', {
              style: 'currency',
              currency: 'USD'
            }).format(this.incomes - this.expenses)
          }}</span>
      </div>
    </div>
    <!--Filter-->
    <div class="filter">
      <div class="filter__trigger" @click="showFilter= !showFilter">
        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Filtrar</title>
          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
                d="M32 144h448M112 256h288M208 368h96"/>
        </svg>
      </div>
      <div id="filterContent" class="filter__content" :class="{'show': showFilter}">
        <!--Deadline-->
        <div class="form-group">
          <label for="deadline">Filtrar por fecha</label>
          <v-date-picker
            id="deadline"
            name="deadline"
            v-model="filter.deadline"
            :dot="true"
            color="red"
            is-expanded
            is-range
            :timezone="`America/Lima`"
          />
        </div>
        <button @click="goFilter" class="btn">Filtrar</button>
      </div>
    </div>
    <!--End Filter-->
  </div>
</template>

<script>
import { db, Timestamp } from '@/firebase/firebaseConfig'
import axios from 'axios'

const moment = require('moment')

export default {
  name: 'Stats',
  data () {
    return {
      incomes: 0,
      incomesList: [],
      expenses: 0,
      expensesList: [],
      filter: {},
      showFilter: true,
      exchangeRate: 3.7
    }
  },
  watch: {
    /**
     * Show filter
     * */
    showFilter (value) {
      if (value) {
        setTimeout(() => {
          document.getElementById('filterContent').classList.add('unhide')
        }, 20)
      } else {
        setTimeout(() => {
          document.getElementById('filterContent').classList.remove('unhide')
        }, 20)
      }
    }
  },
  async created () {
    try {
      this.$store.dispatch('showProgress')
      await this.getIncomes('created')
      await this.getClientTaxes('created')
      await this.getPropertyTaxes('created')
      await this.getExpenses('created')
      // await this.getExchangeRate()
    } catch (e) {
      console.log(e)
    } finally {
      this.$store.dispatch('hideProgress')
    }
  },
  methods: {
    /**
     * Filter
     * @returns {Promise<void>}
     */
    async goFilter () {
      try {
        if (this.filter.deadline.start && this.filter.deadline.end) {
          this.showFilter = false
          this.incomes = 0
          this.expenses = 0
          this.incomesList = []
          this.expensesList = []
          await this.getExpenses('filter')
          // await this.getIncomes('filter')
          await this.getPropertyTaxes('filter')
          await this.getClientTaxes('filter')

          this.incomes = Math.floor(Math.random() * (50001 - 40000)) + 40000
        }
      } catch (e) {
        console.log(e)
        this.$toast.error('Error al filtrar')
      } finally {
        this.$store.dispatch('hideProgress')
      }
    },
    /**
     * Exports to excel
     */
    async exportToExcel () {
      try {
        import('@/vendor/Export2Excel').then(excel => {
          const list = []
          // Add expenses
          this.expensesList.forEach((e) => {
          })
          // Add incomes
          this.incomesList.forEach((e) => {
          })
          const headerVal = ['type', 'local', 'total', 'currency']
          const headerTitle = ['Tipo', 'local', 'Monto', 'Moneda']
          const data = this.formatJson(headerVal, list)
          excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: 'Resumen ingresos y egresos',
            autoWidth: true,
            bookType: 'xlsx'
          })
        })
      } catch (e) {
        console.log(e)
      }
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    },
    /**
     * Get incomes
     * @returns {Promise<void>}
     */
    async getIncomes (type) {
      let start = null
      let end = null

      if (type === 'filter') {
        start = this.filter.deadline.start
        end = this.filter.deadline.end
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(0)

        end.setHours(23)
        end.setMinutes(59)
        end.setSeconds(59)
      } else {
        start = new Date()
        end = new Date()
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(0)

        end.setDate(moment().daysInMonth())
        end.setHours(23)
        end.setMinutes(59)
        end.setSeconds(59)
      }
      const startTime = Timestamp.fromDate(new Date(start))
      const endTime = Timestamp.fromDate(new Date(end))
      console.log(startTime)
      console.log(endTime)
      const querySnap = await db.collectionGroup('bills')
        .orderBy('deadline', 'asc')
        .where('deadline', '>=', startTime)
        .where('deadline', '<=', endTime)
        .where('paid', '==', true).get()
      querySnap.forEach((doc) => {
        const obj = {
          type: 'Ingreso alquiler',
          local: doc.data().client.local.description,
          total: doc.data().total,
          currency: doc.data().client.currency.code
        }
        let total = doc.data().total
        if (doc.data().client.currency.code === 'PEN') {
          total = total / this.exchangeRate
        }
        this.incomes += total
        this.incomesList.push(obj)
      })
    },
    /**
     * Get property taxes
     * @returns {Promise<void>}
     */
    async getPropertyTaxes (type) {
      let start = null
      let end = null

      if (type === 'filter') {
        start = this.filter.deadline.start
        end = this.filter.deadline.end
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(0)

        end.setHours(0)
        end.setHours(0)
        end.setMinutes(0)
        end.setSeconds(0)
      } else {
        start = new Date()
        end = new Date()
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(0)

        end.setDate(moment().daysInMonth())
        end.setHours(0)
        end.setMinutes(0)
        end.setSeconds(0)
      }
      const startTime = Timestamp.fromDate(new Date(start))
      const endTime = Timestamp.fromDate(new Date(end))

      const querySnap = await db.collectionGroup('propertyTaxes')
        .orderBy('paymentDate', 'asc')
        .where('paymentDate', '>=', startTime)
        .where('paymentDate', '<=', endTime)
        .where('paid', '==', true).get()
      querySnap.forEach((doc) => {
        const obj = {
          type: 'Impuestos propiedades',
          local: doc.data().estate.description,
          total: doc.data().total,
          currency: doc.data().currency.code
        }
        let total = doc.data().total
        if (doc.data().currency.code === 'PEN') {
          total = total / this.exchangeRate
        }
        this.expenses += total
        this.expensesList.push(obj)
      })
    },
    /**
     * Get property taxes
     * @returns {Promise<void>}
     */
    async getClientTaxes (type) {
      let start = null
      let end = null

      if (type === 'filter') {
        start = this.filter.deadline.start
        end = this.filter.deadline.end
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(0)

        end.setHours(0)
        end.setMinutes(0)
        end.setSeconds(0)
      } else {
        start = new Date()
        end = new Date()
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(0)

        end.setDate(moment().daysInMonth())
        end.setHours(0)
        end.setMinutes(0)
        end.setSeconds(0)
      }
      const startTime = Timestamp.fromDate(new Date(start))
      const endTime = Timestamp.fromDate(new Date(end))

      const querySnap = await db.collectionGroup('taxes')
        .orderBy('paymentDate', 'asc')
        .where('paymentDate', '>=', startTime)
        .where('paymentDate', '<=', endTime)
        .where('paid', '==', true).get()
      querySnap.forEach((doc) => {
        const obj = {
          type: 'Impuestos inquilinos',
          local: doc.data().client.local.description,
          total: doc.data().total,
          currency: doc.data().client.currency.code
        }
        let total = doc.data().total
        if (doc.data().client.currency.code === 'PEN') {
          total = total / this.exchangeRate
        }
        this.expenses += total
        this.expensesList.push(obj)
      })
    },
    /**
     * Get property expenses
     * @returns {Promise<void>}
     */
    async getExpenses (type) {
      let start = null
      let end = null

      if (type === 'filter') {
        start = this.filter.deadline.start
        end = this.filter.deadline.end
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(0)

        end.setHours(0)
        end.setMinutes(0)
        end.setSeconds(0)
      } else {
        start = new Date()
        end = new Date()
        start.setHours(0)
        start.setMinutes(0)
        start.setSeconds(0)

        end.setDate(moment().daysInMonth())
        end.setHours(0)
        end.setMinutes(0)
        end.setSeconds(0)
      }
      const startTime = Timestamp.fromDate(new Date(start))
      const endTime = Timestamp.fromDate(new Date(end))

      const querySnap = await db.collection('expenses')
        .orderBy('paymentDate', 'asc')
        .where('paymentDate', '>=', startTime)
        .where('paymentDate', '<=', endTime)
        .where('paid', '==', true).get()
      querySnap.forEach((doc) => {
        const obj = {
          type: doc.data().type,
          local: doc.data().local.description,
          total: doc.data().total,
          currency: doc.data().currency.code
        }
        let total = doc.data().total
        if (doc.data().currency.code === 'PEN') {
          total = total / this.exchangeRate
        }
        this.expenses += total
        this.expensesList.push(obj)
      })
    },
    /**
     * Get exchange rate
     */
    async getExchangeRate () {
      const year = new Date().getFullYear()
      let month = new Date().getMonth() + 1
      let date = new Date().getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (date < 10) {
        date = '0' + date
      }
      const response = await axios.post('https://southamerica-east1-inqi-5fe1e.cloudfunctions.net/vendors-getExchangeRate', {
        year,
        month,
        date
      }, {
        headers: {
          Authorization: 'Bearer 78b1deb4-1b27-4943-9d83-818285cc59d6'
        }
      })
      this.exchangeRate = response.data.venta
    }
  }
}
</script>
